import React from 'react';
import Plot from 'react-plotly.js';
import dailyService from '../_services/daily.service'
import parseGraphData from '../lib/parseGraphData'

class LandingPlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], 
      layout: {
                title: 'Take of Trades - Day Trading AI Guide',
                dragmode: 'zoom', 
                margin: {
                    r: 10, 
                    t: 25, 
                    b: 40, 
                    l: 60
                  }, 
                showlegend: true, 
                xaxis: {
                    autorange: true, 
                    domain: [0, 1],
                    type: 'date',
                    rangeslider: {
                        visible: false
                      }
                  }, 
                yaxis: {
                autorange: true, 
                domain: [0, 1], 
                range: [114.609999778, 137.410004222], 
                type: 'linear'
                },
                font: {
                    family: 'Courier New, monospace',
                    size: 13,
                    color: '#0d6efd'
                  },
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
              }, 
      frames: [], 
      config: { displayModeBar: true }
    };
  }

  loadDailyData = (symbol)=> {
    dailyService.get(symbol).then(
        (res) => {
            try {                
                let data = parseGraphData(res.data.data, ['EMA', 'KC'])
                // let layout = parseLayoutData(res.data.data)
                this.setState({
                  data: data
                }) 
                console.log(this.state)
            } catch (error) {
              alert('Nothing found')  
            }
        },
        (err) => {}
      )
}
  componentDidMount() {
    this.loadDailyData('SPY');
  }

  render() {
    return (
        <div className="container col-xxl-8 px-4 pt-2 pb-3">
          <div className="row flex-lg-row-reverse align-items-center">
              <Plot className="col-lg-12"
                data={this.state.data}
                layout={this.state.layout}
                frames={this.state.frames}
                config={this.state.config}
                onInitialized={(figure) => this.setState(figure)}
                onUpdate={(figure) => this.setState(figure)}
              />
          </div>
        </div>
    );
  }
}
export default LandingPlot;