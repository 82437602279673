import React from 'react';
import Plot from 'react-plotly.js';
import parseGraphData from '../lib/parseGraphData'
import parseLayoutData from '../lib/parseLayoutData';
class MainPlot extends React.Component {
  constructor(props) {
    super(props);
    let data = parseGraphData(props.data)
    let layout = parseLayoutData(props.data)
    this.state = {
      data: data, 
      layout: layout, 
      frames: [], 
      config: { scrollZoom: true }
    };
  }

  componentWillReceiveProps(nextProps) {
    let data = parseGraphData(nextProps.data, nextProps.algs)
    let layout = parseLayoutData(nextProps.data)
    this.setState({
      data: data, 
      layout: layout
    })       
  }

  render() {
    return (
      <Plot className="col-lg-12"
        data={this.state.data}
        layout={this.state.layout}
        frames={this.state.frames}
        config={this.state.config}
        onInitialized={(figure) => this.setState(figure)}
        onUpdate={(figure) => this.setState(figure)}
        onClick={(e) => {console.log(e)}}
      />
    );
  }
}
export default MainPlot;