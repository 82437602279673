import React from 'react';
import SignUp from './SignUp'
import SignIn from './SignIn'

function SignInOut() {    
  return (
    <div className="container col-xl-10 col-xxl-8 px-4 p-3">
        <div className="row g-lg-3 py-3">
          <SignUp />
          <SignIn />          
        </div>
      </div>
  );
}

export default SignInOut;
