
const emptyOrder = (data) => {
    return {
        type: 'BUY',
        status: 'COMPLETED',
        shares: 0,
        mkt_price: 0.00,
        symbol: '',
        est_cost: 0.00,
        limit_price: 0.00
      }
}
export default emptyOrder