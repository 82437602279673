import React from 'react';
// import search from '../_services/search.service'
import dailyService from '../_services/daily.service'
class RightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        screenerCode: props.selecetedScreener,
        ticker: props.ticker,
        average_return: 0.0,
        total_max_return: 0.0,
        total_return: 0.00
    }
  }
  componentWillReceiveProps(nextProps) {
      try {
        const ticker =  nextProps.ticker.symbol
        const code =nextProps.screenerCode
        dailyService.getSRevenue({ticker, code}).then(
          (res) => {
              try {
                  const data = res.data.data[0]
                this.setState({
                    average_return: parseFloat(data.average_return).toFixed(2),
                    total_max_return: parseFloat(data.total_max_return).toFixed(2),
                    total_return: parseFloat(data.total_return).toFixed(2),
                    screenerCode: code
                  })
              } catch (error) {
                this.setState({
                    average_return: 0.0,
                    total_max_return: 0.0,
                    total_return: 0.0,
                    screenerCode: 'No screeners'
                  })
              }              
          },
            (err) => {}
        )
      } catch (error) {
          
      }
        
          
    }    
  render() {
        return ( <div className="position-sticky pt-3">
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-primary">Matrics</span>
                <span className="badge bg-primary rounded-pill"></span>
                </h4>
                <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">{this.state.screenerCode}</span>
                        </div>
                    </li>                                   
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">Average Return: </span>
                            <span className="text-primary">{this.state.average_return}</span>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">Total Max Return: </span>
                            <span className="text-primary">{this.state.total_max_return}</span>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">Total Return:  </span>
                            <span className="text-primary">{this.state.total_return}</span>
                        </div>
                    </li>
                </ul>
                <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-primary">P.O.I.S.E Recomendations</span>
                <span className="badge bg-primary rounded-pill"></span>
                </h4>
                <ul className="list-group mb-3">                                    
                    <li className="list-group-item d-flex justify-content-between bg-light">
                    <div>
                        <select className="form-select text-primary" id="state" required>                                        
                        <option>SMA Algo</option>
                        <option>BB</option>
                    </select>
                    </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">Top 5 Performing alogorithms</span>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">Alg 1</span>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">Alg 2</span>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div>
                            <span className="text-primary">Alg 3</span>
                        </div>
                    </li>
                </ul>
            </div>
    );
  }
}
export default RightPanel;