import React from 'react';
import MainPlot from '../_components/MainPlot'
import Header from '../_components/Header'
import PaperTrade from '../_components/PaperTrade'
import StockHistory from '../_components/StockHistory'
import Performance from '../_components/Performance'
import ChooseAlg from '../_components/ChooseAlg'
import Screener from '../_components/Screener'
import DetailedAnalysis from '../_components/DetailedAnalysis'
import RightPanel from './RightPanel';
import searchService from '../_services/search.service'
import orderService from '../_services/order.service'
import dailyService from '../_services/daily.service'
import emptyOrder from '../lib/emptyOrder'
class Dashboard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            ticker: null,
            tickerSearch: [],
            order: emptyOrder,
            data: [],
            isScreenerPage: false,
            orders: [],
            selectedAlg: [],
            selecetedScreener: 'No screeners',
            listTitle: 'Pickers'
      } 
    }

    search = (q)=> {
        searchService.search(q).then(
            (res) => {
                try {
                    const data = res.data.data.splice(0, 8)
                    this.updateStateAfterSearch(data, '', 'Search Results')
                    
                } catch (error) {
                  alert('Nothing found')  
                }
            },
            (err) => {}
          )
    }

    updateStateAfterSearch = (data, screener, title = 'Search Results') => {
        try {
            this.loadDailyData(data[0].symbol)
            this.setState(
                {
                    tickerSearch: data,
                    ticker: data[0],
                    order: { ...this.state.order, ...{symbol:  data[0].symbol}},
                    isScreenerPage: false,
                    selectedAlg: [],
                    selecetedScreener: screener,
                    listTitle: title
                }
            )
        } catch (error) {
            alert('Nothing Found')            
        }
        
    }

    loadDailyData = (symbol)=> {
        dailyService.get(symbol).then(
            (res) => {
                try {
                    this.setState(
                        {
                            data: res.data.data.reverse()
                        }
                    )                    
                } catch (error) {
                  alert('Nothing found')  
                }
            },
            (err) => {}
          )
    }

    updateOrder = (update) =>{
        if(!this.state.ticker) {
            alert('Choose any symbol')
            return
        }
        update['est_cost'] = parseFloat(update['shares'] * update['limit_price']).toFixed(2) | 0.00
        update['symbol'] = this.state.ticker.symbol
        this.setState({order: update})
    }

    clearOrder = () => {
        this.setState({
            order: emptyOrder,
            orders: []
        })
    }

    placeOrder = ()=> {
        let order = this.state.order
        if(!order.symbol || !order.shares || !order.limit_price){
            alert('Order params missing')
            return
        }
        orderService.place(this.state.order).then(
            (res) => {
                if(res.status === 201){
                    alert("Order completed")
                    this.clearOrder()
                } else {
                    alert(res.data.error)
                }
            },
            (err) => {}
          )        
    }

    selectTicker = (i) => {
        let order = this.state.order
        let ticker = this.state.tickerSearch[i]
        this.loadDailyData(ticker.symbol)
        order['symbol'] = ticker.symbol
        this.setState(
            {
                ticker: ticker,
                order: order
            }
        )
    }

    showHideScreener = (e) => {
        if (this.state.isScreenerPage) {
            this.search('SPY')
        }
        this.setState({
            isScreenerPage: !this.state.isScreenerPage
        })
    }
    updateAlgGraph = (alg) => {
        this.setState({
            selectedAlg: alg
        })
    }
    componentDidMount() {
        this.search('SPY');
      }
    render() {
        let Page;
        if(this.state.isScreenerPage) {
            Page = <Screener updateStateAfterSearch={this.updateStateAfterSearch}></Screener>
        } else {
            Page = <div className="col-md-8 col-lg-8 px-md-4">
                        <ChooseAlg updateAlgGraph={this.updateAlgGraph}></ChooseAlg>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <MainPlot data={this.state.data} algs={this.state.selectedAlg} className="my-4 w-100" id="myChart" />
                        </div>
                    </div>
        }
        return  (<div>
                    <Header search={this.search} showHideScreener={this.showHideScreener} isScreenerPage={this.state.isScreenerPage}></Header>
                    <div className="container-fluid">
                        <div className="row">
                            <nav id="sidebarMenu" className="col-md-2 col-lg-2 d-md-block sidebar collapse">
                                <div className="position-sticky pt-3">
                                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                                        <span className="text-primary">{this.state.listTitle}</span>
                                        <span className="badge bg-primary rounded-pill"></span>
                                    </h4>
                                    <ul className="list-group">
                                        { this.state.tickerSearch.map((t, i) =>
                                            <li onClick={() => this.selectTicker(i)} key={i} className={"list-group-item " + (this.state.ticker.symbol === t.symbol? 'list-group-item-primary' : 'list-group-item-light')}>
                                                <div>
                                                    <h6 className="my-0">{t.symbol}</h6>
                                                    <small>{t.name}</small>
                                                </div>
                                            </li>) 
                                        }
                                    </ul>
                                </div>
                            </nav>
                            {Page}
                            <nav id="sidebarMenu" className="col-md-2 col-lg-2 d-md-block sidebar collapse">
                                <RightPanel ticker= {this.state.ticker} screenerCode={this.state.selecetedScreener}></RightPanel>
                            </nav>
                        </div>
                        <div className="row">                            
                            <PaperTrade order={this.state.order} update={this.updateOrder} placeOrder={this.placeOrder}></PaperTrade>
                            <StockHistory ticker={this.state.ticker} ></StockHistory>
                            <Performance ticker={this.state.ticker} ></Performance>
                            <DetailedAnalysis></DetailedAnalysis>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Dashboard;