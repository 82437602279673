import React from 'react';
// import search from '../_services/search.service'
import orderService from '../_services/order.service'
class Performance extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.ticker || {}
    }
    getPerformance(ticker) {                 
        orderService.getPerformance(ticker.symbol).then(
            (res) => {
                let data = res.data
                let total_return, return_percentage
                total_return = parseFloat(data.sell_est_cost - data.buy_est_cost).toFixed(2)
                return_percentage = parseFloat((total_return / data.buy_est_cost || 0) * 100).toFixed(2)
                this.setState({ ...ticker, total_return, return_percentage})
            },
            (err) => {
                this.setState(ticker)
            }
        )        
    }
    componentWillReceiveProps(nextProps) { 
        try {
            this.getPerformance(nextProps.ticker)
        } catch (error) {
            
        }       
    }
    
    render() {
        return (
            <div className="col-md-3 col-lg-3 order-md-last">
                <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between lh-sm">
                        <h4 className="d-flex justify-content-between align-items-center">
                            <span className="text-primary">Performance</span>
                            <span className="badge bg-primary rounded-pill"></span>
                        </h4>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="text-primary">
                            <strong><span>Total Return</span></strong>
                        </div>
                        <span className="text-primary">{this.state.total_return}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="text-primary">
                            <strong><span>Total %</span></strong>
                        </div>
                        <span className="text-primary">{this.state.return_percentage}</span>
                    </li>                                
                </ul>
            </div>
        );
  }
}
export default Performance;