import React from 'react';

import authService from './../_services/auth.service'
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.emptyState()
    this.handleUserInput = this.handleUserInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  emptyState = () => {
      let state = {
        name: '',
        email: '',
        password: '',
        formErrors: {email: '', password: ''},
        emailValid: false,
        passwordValid: false,
        nameValid: false,
        formValid: false,
        onSuccess: false
      }
    return state
  }

  onSuccess = () => {
        let state = this.emptyState()
        state.onSuccess = true
        this.setState(state)
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let passwordValid = this.state.passwordValid;

    switch(fieldName) {
        case 'name':
            nameValid = value.length >= 6;
            fieldValidationErrors.name = nameValid ? '' : ' is invalid';
        break;
        case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid';
            break;
        case 'password':
            passwordValid = value.length >= 6;
            fieldValidationErrors.password = passwordValid ? '': ' is too short';
            break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    emailValid: emailValid,
                    passwordValid: passwordValid,
                    nameValid: nameValid
                  }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.emailValid && this.state.passwordValid && this.state.nameValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  handleSubmit(e) {
      e.preventDefault()
      let state = this.state;
      let name = state.name;
      let email = state.email;
      let password = state.password;
      authService.register(name, name, name, email, password).then(
        this.onSuccess,
        (err) => {}
      )
  }

  render() {
    const onSuccess = this.state.onSuccess
    let notificatin;
    if (onSuccess) {
            notificatin = 
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Registered!</strong> succesfully completed registration.
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>;
    }
    return (        
        <div className="col-md-10 mx-auto col-lg-5">
              <form className="p-4 p-md-4 border rounded-3 bg-light">
                { notificatin }
                <div className="form-floating mb-4">
                    <input type="text" name="name" className="form-control" id="floatingName" placeholder="Name" value={this.state.name}
                        onChange={this.handleUserInput} ></input>
                    <label htmlFor="floatingName">Name</label>                    
                </div>
                <div className="form-floating mb-4">
                    <input type="email" name='email' className="form-control" id="floatingEmail" placeholder="name@example.com" value={this.state.email} onChange={this.handleUserInput}></input>
                    <label htmlFor="floatingEmail">Email address</label>
                        
                </div>
                <div className="form-floating mb-4">
                    <input type="password" name="password" className="form-control" id="floatingPassword" placeholder="Password" value={this.state.password}
                        onChange={this.handleUserInput} ></input>
                    <label htmlFor="floatingPassword">Password</label>
                </div>
                <button className="w-100 btn btn-lg btn-primary" type="submit" onClick={this.handleSubmit}>Sign Up</button>
              </form>
          </div>        
    );
  }
}
export default SignUp;