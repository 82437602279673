
import { pluck, map } from "underscore"
import moment from "moment"
const parseLayoutData = (data) => {
    let dates = map(pluck(data, 'date'), (d) => moment(d).utc().format('YYYY-MM-DD'))
    let high = pluck(data, 'high')
    let low = pluck(data, 'low')
    let low_range = Math.min(...low) || 0
    let high_range = Math.max(...high) || 0
    let x_ax = {
      domain: [0, 1], 
      title: 'Date', 
      type: 'date',
      rangeslider: {
          visible: false
        }
    }
    if(!dates) {
      x_ax.autorange = true
    } else {
      x_ax.range = [dates[180], dates[0]]
    }
    let y_ax = {
      domain: [0, 1], 
      type: 'linear'
      }
    if(!dates) {
      y_ax.autorange = true
    } else {
      y_ax.range = [low_range, high_range]
    }

    return {
        title: 'Take of Trades - Day Trading AI Guide',
        dragmode: 'zoom', 
        margin: {
            r: 10, 
            t: 25, 
            b: 40, 
            l: 60
          }, 
        showlegend: false, 
        xaxis: x_ax, 
        yaxis: y_ax,
        font: {
            family: 'Courier New, monospace',
            size: 13,
            color: '#0d6efd'
          },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
      }
}
export default parseLayoutData