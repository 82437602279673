import React from 'react';
// import search from '../_services/search.service'
import orderService from '../_services/order.service'
class StockHistory extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.ticker)
        this.state = props.ticker || {}
    }
    getHistory(ticker) { 
            orderService.getHistory(ticker.symbol).then(
                (res) => {
                    try {
                        let data = res.data
                        let avg_sell_price, avg_buy_price, total_return, return_percentage
                        avg_buy_price = parseFloat(data.buy_est_cost / data.buy_shares || 0).toFixed(2)
                        avg_sell_price = parseFloat(data.sell_est_cost / data.sell_shares || 0).toFixed(2)
                        total_return = parseFloat(data.sell_est_cost - data.buy_est_cost).toFixed(2)
                        return_percentage = parseFloat((total_return / data.buy_est_cost || 0) * 100).toFixed(2)
                        this.setState({ ...ticker, avg_sell_price, avg_buy_price, total_return, return_percentage})
                    } catch (error) {
                        
                    }
                    
                },
                (err) => {
                    this.setState(ticker)
                }
            )        
    }
    componentWillReceiveProps(nextProps) {
        try {
            this.getHistory(nextProps.ticker)  
        } catch (error) {
            
        }        
    }
    
    render() {
        return (
            <div className="col-md-3 col-lg-3 order-md-last">
                <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between lh-sm">
                        <h4 className="d-flex justify-content-between align-items-center">
                            <span className="text-primary">History - This stock</span>
                            <span className="badge bg-primary rounded-pill"></span>
                        </h4>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="input-group d-flex justify-content-between mx-2">
                            <div className="text-primary">
                                <strong><span>Avg Buy Price : </span></strong>
                            </div>
                            <span className="text-primary float-end">{this.state.avg_buy_price || ''}</span>
                        </div>
                        <div className="input-group d-flex justify-content-between mx-2">
                            <div className="text-primary">
                                <strong><span>Avg Sell Price : </span></strong>
                            </div>
                            <span className="text-primary float-end">{this.state.avg_sell_price || ''}</span>
                        </div>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="text-primary mx-2">
                            <strong><span>Total Return :</span></strong>
                        </div>
                            <span className="text-primary">{this.state.total_return || ''}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                        <div className="text-primary mx-2">
                            <strong><span>Total % :</span></strong>
                        </div>
                        <span className="text-primary">{this.state.return_percentage || ''}</span>
                    </li>
                </ul>
            </div>
        );
  }
}
export default StockHistory;