import axios from "axios";
import generateRequest from './auth-header'
const API_URL = "https://api.takeofftrades.com/order/";

const place = (data) => {
  return axios(generateRequest('post', API_URL+'place', data ));
};
const get = (data) => {
    return axios(generateRequest('get', API_URL + 'all', {}));
};
const getHistory = (symbol) => {
    return axios(generateRequest('get', API_URL + 'history?symbol=' + symbol));
};
const getPerformance = (symbol) => {
    return axios(generateRequest('get', API_URL + 'performance?symbol=' + symbol));
};

const orderService = {
    place,
    get,
    getHistory,
    getPerformance
};

export default orderService;