import React from 'react';
// import search from '../_services/search.service'
import dailyService from '../_services/daily.service'
class Screener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'SCREENER',
      alg: 'SMA', 
      op: 'GT',
      op1: 'SMA',
      op2: 'SMA10', 
      screenerCode: 'EMA5EMA50',
      screener_ema_5_50: true
    }
  }
  switchTab = (tab)=> {
    this.setState({
      tab: tab
    })
  }
  changeOp = (event) => {
    event.stopPropagation()
    this.setState({op: event.target.value});
  }
  changeOp1 = (event) => {
    event.stopPropagation()
    this.setState({op1: event.target.value});
  }
  changeOp2 = (event) => {
    event.stopPropagation()
    this.setState({op2: event.target.value});
  }
  changeVal = (event) => {
    event.stopPropagation()
    this.setState({value: event.target.value});
  }
  search = () => {
    dailyService.getSMA(this.state).then(
      (res) => {
        this.props.updateStateAfterSearch(res.data.data, 'Unguided',this.state.op1 + ' ' + this.state.op2)
      },
      (err) => {}
    )
  }
  searchSEmaFiveFifty = () => {
    dailyService.getSEmaFiveFifty(this.state).then(
      (res) => {
        this.props.updateStateAfterSearch(res.data.data, this.state.screenerCode, this.state.screenerCode)
      },
      (err) => {}
    )
  }
  render() {
    let content;
    if(this.state.tab === 'MOVING_AVERAGE') {
      content = <ul className="list-group list-group-horizontal w-100 pb-2">                
                    <li className="list-group-item w-25">
                      <select id="inputState" className="btn btn-outline-primary dropdown-toggle w-100" onChange={this.changeOp1} value={this.state.op1}>
                        <option value='SMA'>PRICE</option>
                        <option value='SMA10'>SMA 10</option>
                        <option value='SMA20'>SMA 20</option>
                        <option value='SMA50'>SMA 50</option>
                        <option value='SMA100'>SMA 100</option>
                        <option value='SMA200'>SMA 200</option>
                      </select>
                      </li>
                    <li className="list-group-item w-25">
                      <select id="inputState" className="btn btn-outline-primary dropdown-toggle w-100" onChange={this.changeOp} value={this.state.op}>
                        <option value='GT'>GREATER THAN</option>
                        <option value='LT'>LESS THAN</option>
                        <option value='ET'>EQUAL TO</option>
                        <option value='GTE'>GREATER THAN</option>
                        <option value='LTE'>LESS THAN</option>
                      </select>
                    </li>
                    <li className="list-group-item w-25">
                      <select id="inputState" className="btn btn-outline-primary dropdown-toggle w-100" onChange={this.changeOp2} value={this.state.op2}>
                        <option value='SMA10'>SMA 10</option>
                        <option value='SMA20'>SMA 20</option>
                        <option value='SMA50'>SMA 50</option>
                        <option value='SMA100'>SMA 100</option>
                        <option value='SMA200'>SMA 200</option>
                        <option value='SMA'>PRICE</option>
                      </select>
                    </li>
                    <li className="list-group-item w-25">
                      <button type="submit" className="btn btn-outline-primary w-100" onClick={this.search}>Search</button>
                    </li>
                </ul>
    } else {
      content = <ul className="list-group list-group-horizontal w-100 pb-2">
                    <li className="list-group-item w-100">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title text-primary">EMA Crosses</h5>
                          <div class="form-check">
                            <input class="form-check-input" onClick={this.searchSEmaFiveFifty} type="radio" name="exampleRadios" id="exampleRadios1" value={this.state.screener_ema_5_50} checked></input>
                            <label class="form-check-label text-primary" for="exampleRadios1">
                              Screener ema5 > ema50
                            </label>                          
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" onClick={this.searchSEmaFiveFifty} type="radio" name="exampleRadios" id="exampleRadios1" value={this.state.screener_ema_5_50} checked></input>
                            <label class="form-check-label text-primary" for="exampleRadios1">
                              Screener ema5 > ema20
                            </label>                          
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item w-100">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title text-primary">SMA Crosses</h5>
                          <div class="form-check">
                            <input class="form-check-input" onClick={this.searchSEmaFiveFifty} type="radio" name="exampleRadios" id="exampleRadios1" value={this.state.screener_ema_5_50} checked></input>
                            <label class="form-check-label text-primary" for="exampleRadios1">
                              Screener sma5 > sma50
                            </label>                          
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" onClick={this.searchSEmaFiveFifty} type="radio" name="exampleRadios" id="exampleRadios1" value={this.state.screener_ema_5_50} checked></input>
                            <label class="form-check-label text-primary" for="exampleRadios1">
                              Screener sma5 > sma20
                            </label>                          
                          </div>
                        </div>
                      </div>
                    </li>
                </ul>
    }
    return (        
        <div className="col-md-8 col-lg-8 px-md-4">
            <ul className="list-group list-group-horizontal pb-2 mb-3 border-bottom">
            <li className={"list-group-item " + (this.state.tab === 'SCREENER'? 'list-group-item-primary' : 'list-group-item-light')} onClick={() => this.switchTab('SCREENER')}>
                  Guided Screeners
                </li>
                <li className={"list-group-item " + (this.state.tab === 'MOVING_AVERAGE'? 'list-group-item-primary' : 'list-group-item-light')} onClick={() => this.switchTab('MOVING_AVERAGE')}>
                  Unguided Screeners
                </li>                
            </ul>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
              {content}       
            </div>            
        </div>
    );
  }
}
export default Screener;