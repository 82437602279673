import axios from "axios";

const API_URL = "https://api.takeofftrades.com/users/";

const register = (first_name, last_name, full_name, email, password) => {
  return axios.post(API_URL + "register", {
    first_name,
    last_name,
    full_name,
    email,
    password,
  });
};

const login = (email, password) => {
 
  return axios
    .post(API_URL + "login", {
      "email": email, 
      "password": password
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const authService = {
    register,
    login,
    logout,
    getCurrentUser,
  };


export default authService;