import axios from "axios";

const SEARCH_URL = "https://d55pdz9vo4.execute-api.us-east-2.amazonaws.com/api/symbol?query=";

const search = (q) => {
  return axios.get(SEARCH_URL + q);
};

const searchService = {
    search
  };

export default searchService;