import React from 'react';

class VideoGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
        <div className="container col-xl-10 col-xxl-8 px-4 p-3">
        <div className="row">
           <div className="col-lg-4 col-md-12 mb-4">
              <div className="card mx-auto w-r-18">
                 <div className="card-img-top">
                    <iframe className='w-r-18' src="https://www.youtube.com/embed/vlDzYIIOYmM" title='a'></iframe>
                 </div>
                 <div className="card-body">
                    <h5 className="card-title">Moving Average</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <button className="btn btn-primary">Go somewhere</button>
                 </div>
              </div>
           </div>
           <div className="col-lg-4 col-md-12 mb-4">
              <div className="card mx-auto w-r-18">
                 <div className="card-img-top">
                    <iframe className='w-r-18' src="https://www.youtube.com/embed/vlDzYIIOYmM" title='b'></iframe>
                 </div>
                 <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <button className="btn btn-primary">Go somewhere</button>
                 </div>
              </div>
           </div>
           <div className="col-lg-4 col-md-12 mb-4">
              <div className="card mx-auto w-r-18">
                 <div className="card-img-top">
                    <iframe className='w-r-18' src="https://www.youtube.com/embed/vlDzYIIOYmM" title='c'></iframe>
                 </div>
                 <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    <button className="btn btn-primary">Go somewhere</button>
                 </div>
              </div>
           </div>
        </div>
     </div>
    );
  }
}
export default VideoGrid;