import React from 'react';
// import search from '../_services/search.service'
class PaperTrade extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.order || null
    }  
    componentWillReceiveProps(nextProps) {
        this.setState(nextProps.order)
    }
    update = (e) => {
        e.preventDefault()
        const name = e.target.name;
        const value = e.target.value;
        let state = this.state
        state[name] = value
        this.props.update(state)
    }
  render() {
    let button;
    if (this.state.type === 'BUY') {
        button = <div className="text-primary">
                    <button type="button" className="btn btn-outline-primary" onClick={this.props.placeOrder}>Buy</button>
                </div>
    } else {
        button = <div className="text-primary">
                    <button type="button" className="btn btn-outline-primary" onClick={this.props.placeOrder}>Sell</button>
                </div>
    }
    return (
        <div className="col-md-3 col-lg-3 order-md-last">   
            <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-sm">
                    <h4 className="d-flex justify-content-between align-items-center">
                        <span className="text-primary">Paper Trading</span>
                        <span className="badge bg-primary rounded-pill"></span>
                    </h4>
                </li>
                <li className="list-group-item justify-content-between lh-sm">
                    <div>
                        <select className="form-select text-primary" name='type' id="state" value={this.state.type} required onChange={this.update}>                                        
                            <option value='SELL'>Sell</option>
                            <option value='BUY'>Buy</option>
                        </select>
                    </div>
                </li>                                
                <li className="list-group-item d-flex justify-content-between">
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group">
                                <div class="form-floating">
                                    <input type="text" className="form-control text-primary" name='shares' placeholder="Shares" value={this.state.shares}
                                                            onChange={this.update}></input>
                                    <label for="floatingInputGrid" className="text-primary">Shares</label>
                                </div>
                            </div>                            
                        </div>
                        <div className="col-6">
                            <div className="input-group">
                                <div class="form-floating">
                                    <input type="text" className="form-control text-primary" name='limit_price' placeholder="Limit Price" 
                                    onChange={this.update} value={this.state.limit_price}></input>
                                    <label className="text-primary" for="floatingInputGrid">Limit Price</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                    <div className="row">
                            <div className="col-6">
                                <div class="form-floating">
                                <input type="text" disabled = {true} className="form-control text-primary" onChange={ ()=>{}} placeholder="MKT Prices" value={this.state.mkt_price}></input>
                                        <label for="floatingInputGrid" className="text-primary">MKT Prices</label>
                                    </div>
                            </div>
                            <div className="col-6">
                                <div class="form-floating">
                                <input type="text" disabled = {true} className="form-control text-primary" onChange={ ()=>{}} placeholder="EST Costs" value={this.state.est_cost}></input>
                                        <label for="floatingInputGrid" className="text-primary">EST Costs</label>
                                    </div>
                            </div>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                    {button}
                </li>
            </ul>
        </div>
    );
  }
}
export default PaperTrade;