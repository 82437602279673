import React from 'react';

class DetailedAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.ticker || {}
    }
    componentWillReceiveProps(nextProps) { 
            
    }
    
    render() {
        return (
            <div className="col-md-3 col-lg-3 order-md-last">
                <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between lh-sm">
                        <h4 className="d-flex justify-content-between align-items-center">
                            <span className="text-primary">Detailed Analysis</span>
                            <span className="badge bg-primary rounded-pill"></span>
                        </h4>
                    </li>                                
                </ul>
            </div>
        );
  }
}
export default DetailedAnalysis;