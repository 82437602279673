import React from 'react';
// import search from '../_services/search.service'
class Header extends React.Component {
  constructor(props) {
    super(props);
    let user = JSON.parse(localStorage.getItem("user"))
    this.state = {
        query: "",
        isScreenerPage: props.isScreenerPage,
        user: user,
        user_pro_image: "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80"
    }
  }
  handleSearch = (e) => {
      e.preventDefault()
      const name = e.target.name;
      const value = e.target.value;
      this.setState({[name]: value})      
  }
  search = () => {
    this.props.search(this.state.query)
  }
  componentWillReceiveProps(nextProps) {
      this.setState({
        isScreenerPage: nextProps.isScreenerPage
      })
    }
  searchOnEnterKeyPress = (e) => {
    if (e.key === 'Enter') {        
          this.search()
    }
  }
    
  render() {
    let button;
    if(this.state.isScreenerPage) {
      button = <button type="button" className="btn btn-outline-primary w-100 mx-3 my-1" onClick={this.props.showHideScreener} >Close Screener Page</button>
    } else {
      button = <button type="button" className="btn btn-outline-primary w-100 mx-3 my-1" onClick={this.props.showHideScreener} >Open Screener Page</button>
    }
    return (        
                <header className="pt-2 mb-2 border-bottom">
                    <div className="container-fluid d-grid gap-3 align-items-center" >
                        <div className="d-flex align-items-center">
                            <form className="w-100 me-3" onSubmit={e => { e.preventDefault(); }}>
                                
                                <div className="row">
                                  <div className="col-2">
                                    <h4 className="d-flex justify-content-between align-items-center mx-3">
                                        <span className="text-primary">TakeOff Trades</span>
                                    </h4>
                                  </div>                                     
                                  <div className="col-7">
                                    <div className="row">
                                      <div className="col-12">
                                      <input type="text" name="query" className="form-control mx-3" placeholder="Search Ticker..." value={this.state.query}
                                        onChange={this.handleSearch} onKeyPress={this.searchOnEnterKeyPress}/>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12">
                                      {button}
                                      </div>
                                    </div>
                                  </div>
                                   <div className="col-1">
                                      <button type="button" className="btn btn-outline-primary w-100" onClick={this.search} >Search</button>
                                  </div>
                                  <div className="col-2">

                                  <div className="container d-flex px-0">
                                      <div className="profile w-100">
                                          <div className="d-flex align-items-center">
                                              <div className="image">
                                                <img alt="" src={this.state.user_pro_image} className="rounded" width="60"></img> 
                                              </div>
                                              <div className="px-3 w-100">
                                                  <h4 className="mb-0 mt-0 text-primary">{this.state.user.full_name}</h4>
                                                  {/* <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                                      <div className="d-flex flex-column"> <span className="articles">Articles</span> <span className="number1">38</span> </div>
                                                      <div className="d-flex flex-column"> <span className="followers">Followers</span> <span className="number2">980</span> </div>
                                                      <div className="d-flex flex-column"> <span claclassNamess="rating">Rating</span> <span clclassNameass="number3">8.9</span> </div>
                                                  </div> */}
                                                  <div className="button mt-2 d-flex flex-row align-items-center"> 
                                                  <button className="btn btn-sm btn-outline-primary w-100">Logout</button> 
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  </div>                           
                                </div>
                            </form>
                        </div>
                    </div>
                </header>
    );
  }
}
export default Header;