import React from 'react';
// import search from '../_services/search.service'
class ChooseAlg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        graphList: []
    }
  }
  selectAlg = (alg) => {
        let arr = this.state.graphList.slice()
        if(!arr.includes(alg)){          //checking weather array contain the id
            arr.push(alg);               //adding to array because value doesnt exists
        }else{
            arr.splice(arr.indexOf(alg), 1);  //deleting
        }
        this.setState({
            graphList: arr
        })
    this.props.updateAlgGraph(arr)
  }
  render() {
    return (        
        <div className="border-bottom">
            <ul className="list-group list-group-horizontal pb-2">
                <li className="list-group-item list-group-item-primary">
                    <input className="form-check-input" type="checkbox" id="SMA" onChange={(e) => {this.selectAlg('SMA', e.target.checked)}}/>
                    <label className="form-check-label px-4" htmlFor="SMA">SMA 50</label>
                </li>                
                <li className="list-group-item list-group-item-primary">
                    <input className="form-check-input" type="checkbox" id="BB" onChange={(e) => {this.selectAlg('BB', e.target.checked)}}/>
                    <label className="form-check-label px-4" htmlFor="BB">BB</label>
                </li>
                <li className="list-group-item list-group-item-primary">
                    <input className="form-check-input" type="checkbox" id="ADX" onChange={(e) => {this.selectAlg('ADX', e.target.checked)}}/>
                    <label className="form-check-label px-4" htmlFor="ADX">ADX</label>
                </li>
                <li className="list-group-item list-group-item-primary">
                    <input className="form-check-input" type="checkbox" id="EMA" onChange={(e) => {this.selectAlg('EMA', e.target.checked)}} />
                    <label className="form-check-label px-4" htmlFor="EMA">EMA 50</label>
                </li>
                <li className="list-group-item list-group-item-primary">
                    <input className="form-check-input" type="checkbox" id="KC" onChange={(e) => {this.selectAlg('KC', e.target.checked)}}/>
                    <label className="form-check-label px-4" htmlFor="KC">KC</label>
                </li>
                <li className="list-group-item list-group-item-primary">
                    <input className="form-check-input" type="checkbox" id="KD" onChange={(e) => {this.selectAlg('KD', e.target.checked)}} />
                    <label className="form-check-label px-4" htmlFor="EMA">Stochastic Oscillator</label>
                </li>
                {/* <li className="list-group-item list-group-item-warning">
                    <input className="form-check-input" type="checkbox" id="EMA" onChange={(e) => {this.selectAlg('EMA', e.target.checked)}} />
                    <label className="form-check-label px-5" htmlFor="EMA">StochRSI</label>
                </li> */}
                           
            </ul>
        </div>
    );
  }
}
export default ChooseAlg;