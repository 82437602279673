import axios from "axios";
import generateRequest from './auth-header'
const API_URL = "https://api.takeofftrades.com/data/";


const get = (symbol) => {
    return axios(generateRequest('get', API_URL + 'daily?symbol=' + symbol));
};
const getSMA = (params) => {
    return axios(generateRequest('get', API_URL + 'daily/sma?op=' + params.op + '&op1=' + params.op1 + '&op2=' + params.op2));
};
const getSEmaFiveFifty = (params) => {
    return axios(generateRequest('get', API_URL + 'daily/screener_ema_5_50'));
};
const getSRevenue = (params) => {
    return axios(generateRequest('get', API_URL + 'daily/revenue?code=' + params.code + '&ticker=' + params.ticker ));
};
const dailyService = {
    get,
    getSMA,
    getSEmaFiveFifty,
    getSRevenue
};

export default dailyService;