import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ProvideAuth, useAuth } from './Auth'
import Landing from './_components/Landing'
import Dashboard from './_components/Dashboard'

export default function AuthExample() {
  return (
    <ProvideAuth>
      <Router>
        <div>         
          <Switch>
            <Route path="/public">
              {/* <Dashboard /> */}
              <PublicPage />
            </Route>
            <PrivateRoute path="/protected">
              <Dashboard />
              {/* <PublicPage /> */}
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/public",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function PublicPage() {
  return <Landing />;
}