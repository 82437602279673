import React from 'react';
import {
  useHistory
} from "react-router-dom";

import { useAuth } from '../Auth'

function SignIn() {
    let history = useHistory(); 
    let auth = useAuth();
    let email = React.createRef();
    let password = React.createRef();
    let login = (e) => {
      e.preventDefault()
      auth.signin(email.current.value, password.current.value, () => {
        history.push({ pathname: "/protected" } );
      });
    };
  return (
        <div className="col-md-10 mx-auto col-lg-5">
            <form className="p-4 p-md-4 border rounded-3 bg-light">
                <div className="form-floating mb-4">
                    <input ref={email} type="email" className="form-control" id="floatingInput" placeholder="name@example.com"></input>
                    <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating mb-4">
                    <input ref={password} type="password" className="form-control" id="floatingPassword" placeholder="Password"></input>
                    <label htmlFor="floatingPassword">Password</label>
                </div>
                <button className="w-100 btn btn-lg btn-primary" onClick={login} >Sign In</button>
                <hr className="my-4">
                </hr>
                <small className="text-muted">By clicking Sign up, you agree to the terms of use.</small>
            </form>
        </div>
  );
}

export default SignIn;
