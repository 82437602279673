
import { pluck, map } from "underscore"
import moment from "moment"
const SMA = require('technicalindicators').SMA;
const BB = require('technicalindicators').BollingerBands
const ADX = require('technicalindicators').ADX
const EMA = require('technicalindicators').EMA
const KC = require('keltnerchannel').kc
const Stochastic = require('technicalindicators').Stochastic
const parseGraphData = (data, algs=[]) => {
    let dates = map(pluck(data, 'date'), (d) => moment(d).utc().format('YYYY-MM-DD'))
    let close = pluck(data, 'close')
    let open = pluck(data, 'open')
    let high = pluck(data, 'high')
    let low = pluck(data, 'low')   

    let graphData = {
        name: 'Ticker',
        x: dates,
        close: close,
        open: pluck(data, 'open'),
        low: pluck(data, 'low'),
        high: pluck(data, 'high'),
        decreasing: {line: {color: '#dc3545'}},
        increasing: {line: {color: '#0d6efd'}}, 
        line: {
            color: '#0d6efd',
            width: 1
        },
        type: 'candlestick', 
        xaxis: 'x', 
        yaxis: 'y'
    }
    let graph = []
    graph.push(graphData)
    algs.forEach((alg) => {
        switch (alg) {
            case 'SMA':
                graph.push({
                    name: alg,
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: SMA.calculate({period : 50, values : close}),
                    line: {
                        width: 1
                    }
                })
              break;
            case 'BB':
                let bb = BB.calculate({period : 20, values : close, stdDev : 2})
                let y_m = pluck(bb, 'middle')
                let y_u = pluck(bb, 'upper')
                let y_l = pluck(bb, 'lower')
                graph.push({
                    name: alg +' Middle',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_m,
                    line: {
                        width: 1
                    }
                })
                graph.push({
                    name: alg +' Upper',
                    type: 'scatter',
                    mode: 'lines',
                    line: {
                        color: 'rgb(128, 0, 128)',
                        width: 1
                      },
                    x: dates,
                    y: y_u
                })
                graph.push({
                    name: alg +' Lower',
                    type: 'scatter',
                    mode: 'lines',
                    line: {
                        color: 'rgb(128, 0, 128)',
                        width: 1
                      },
                    x: dates,
                    y: y_l
                })
                break;
            case 'ADX':
                let adx = ADX.calculate({period : 50, close : close, open : open, low: low, high:high})
                let y_adx = pluck(adx, 'adx')
                let y_mdi = pluck(adx, 'mdi')
                let y_pdi = pluck(adx, 'pdi')
                graph.push({
                    name: alg,
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_adx,
                    line: {
                        width: 1
                    }
                })
                graph.push({
                    name: alg + 'MDI',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_mdi,
                    line: {
                        width: 1
                    }
                }) 
                graph.push({
                    name: alg + 'PDI',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_pdi,
                    line: {
                        width: 1
                    }
                })   
                break;
            case 'EMA':
                    let ema = EMA.calculate({period : 50, values : close})
                    graph.push({
                        name: alg +' Lower',
                        type: 'scatter',
                        mode: 'lines',
                        x: dates,
                        y: ema,
                        line: {
                            width: 1
                        }
                    })   
                break;
            case 'KC':
                let kc = KC(data, 2, 2, true);
                let y_upper = kc.upper
                let y_mid = kc.mid
                let y_lower = kc.lower
                graph.push({
                    name: alg +' Upper',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_upper,
                    line: {
                        width: 1
                    }
                })
                graph.push({
                    name: alg +' mid',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_mid,
                    line: {
                        width: 1
                    }
                })
                graph.push({
                    name: alg +' Lower',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_lower,
                    line: {
                        width: 1
                    }
                })   
                break;
            case 'KD':
                let stochastic = Stochastic.calculate({
                    period : 20, 
                    high: high,
                    low: low,
                    close: close,
                    signalPeriod: 3})
                let y_k = pluck(stochastic, 'k')
                let y_d = pluck(stochastic, 'd')
                graph.push({
                    name: 'stochastic',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_k,
                    line: {
                        width: 1
                    }
                })
                graph.push({
                    name: 'stochastic',
                    type: 'scatter',
                    mode: 'lines',
                    x: dates,
                    y: y_d,
                    line: {
                        width: 1
                    }
                })
                break;
            default:
          }
    });    
    return graph
}
export default parseGraphData