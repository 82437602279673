import React, { useContext, createContext, useState } from "react";
import authService from './_services/auth.service'

 var authContext = createContext();

 const fakeAuth = {
    isAuthenticated: false,
    signin(cb) {
      fakeAuth.isAuthenticated = true;
      setTimeout(cb, 100); // fake async
    },
    signout(cb) {
      fakeAuth.isAuthenticated = false;
      setTimeout(cb, 100);
    }
  };

 export function ProvideAuth({ children }) {
   const auth = useProvideAuth();
   return (
     <authContext.Provider value={auth}>
       {children}
     </authContext.Provider>
   );
 }

 export function useAuth() {
   return useContext(authContext);
 }
 
 export function useProvideAuth() {
   const [user, setUser] = useState(null);
 
   const signin = (email, password, cb) => {     
     return authService.login(email, password).then(() => {
        setUser("user");
        cb();
     });
   };
 
   const signout = cb => {
     return fakeAuth.signout(() => {
       setUser(null);
       cb();
     });
   };
 
   return {
     user,
     signin,
     signout
   };
 }